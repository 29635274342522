import React, { Component } from "react"
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Form,
} from "react-bootstrap"
import * as queryString from "query-string"
import { navigate } from "gatsby"
import { Icon } from "react-icons-kit"
import { key } from "react-icons-kit/icomoon/key"
import { eye } from "react-icons-kit/fa/eye"
import { eyeSlash } from "react-icons-kit/fa/eyeSlash"
import { asterisk } from "react-icons-kit/fa/asterisk"
import Metatags from "../../components/metatags"
import { handleChangePassword } from "../../services/auth"
import Navbar from "../../components/navbar"
import Footer from "../../components/footer"
import ErrorModal from "../../components/error-modal"
import "../../css/multilogica-theme.scss"


class PasswordRecovery extends Component {
  constructor(context) {
    super(context)
    const { k } = queryString.parse(context.location.search)
    this.state = {
      token: k,
      formPassword: "",
      errorField: [],
      spinnerVerifying: false,
      isRevealPassword: false,
      modalResult: false,
      modalText: "",
      showErrorConnModal: false,
    }
  }

  passWordRules = {
    data: [
      {
        regex: "^.{8,}",
        errorMessage: "a senha deve ter pelo menos 8 caracteres",
      },
    ],
  }

  ruleClass(errorMessage) {
    const result = this.passWordRules.data.filter(
      msg => msg.errorMessage === errorMessage
    )
    if (new RegExp(result[0].regex).test(this.state.formPassword)) {
      return false
    } else {
      return true
    }
  }

  verifyAllRules(actualPassword) {
    let passOk = true
    this.passWordRules.data.map(rule => {
      if (!new RegExp(rule.regex).test(actualPassword)) {
        passOk = false
      }
      return null
    })
    return passOk
  }

  passWordRulesMsgs() {
    return this.passWordRules.data.map((rule, n) => {
      return (
        <div
          key={n}
          className={this.ruleClass(rule.errorMessage) ? "" : "line-th"}
        >
          {rule.errorMessage}
        </div>
      )
    })
  }

  mandatory = title => {
    return (
      <Form.Label>
        {title}
        <Icon className="mandatory" size={"10px"} icon={asterisk} />
      </Form.Label>
    )
  }

  verifyData = () => {
    let errorFieldAux = []
    if (this.state.formPassword.length < 8) {
      errorFieldAux.push("formPassword")
    }
    if (errorFieldAux.length !== 0) {
      this.setState({
        errorField: errorFieldAux,
      })
    } else {
      this.submitForm()
    }
  }

  submitForm = async () => {
    await handleChangePassword({
      password: this.state.formPassword,
      token: this.state.token,
    }).then(res => {
      if (!res.isAxiosError) {
        if (res.status === 201) {
          this.setState({
            modalResult: true,
            modalText: "Sua senha foi alterada com exito",
          })
        }
      } else {
          this.setState({
            showErrorConnModal: true
          })
      }
    })
  }

  formateField = evt => {
    evt.preventDefault()
    this.setState({
      [evt.target.id]: evt.target.value,
    })
    if (evt.target.value.length >= 8) {
      this.removeError(evt.target.id)
    }
  }

  removeError = errName => {
    if (this.state.errorField.includes(errName)) {
      let errorFieldAux = this.state.errorField
      errorFieldAux.splice(errorFieldAux.indexOf(errName), 1)
      this.setState({
        errorField: errorFieldAux,
      })
    }
  }

  togglePassword = () => {
    this.setState({
      isRevealPassword: !this.state.isRevealPassword,
    })
  }

  removeModal = () => {
    this.setState({ modalResult: false }, () => {
      navigate("/user/signin")
    })
  }
  removeErrorConnModal = () => {
    this.setState({
      showErrorConnModal: !this.state.showErrorConnModal,
    })
  }

  render() {
    const { isRevealPassword } = this.state

    return (
      <>
        <ErrorModal
          modalErro={this.state.showErrorConnModal}
          removeErrorConnModal={this.removeErrorConnModal}
        />
        <Metatags title="" description="" url="" image="" keywords="" />
        <Navbar />
        <div className="space-50" style={{ height: "50px" }} />
        <Modal show={this.state.modalResult}>
          <Modal.Body>
            <p>{this.state.modalText}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.removeModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <Container>
          <Row>
            <Col md={4} />
            <Col md={4}>
              <Container>
                <Row>
                  <Col>
                    <div className="text-center">
                      <h3>Gere uma nova senha</h3>
                    </div>
                    <div className="height-40" />
                    <Form.Group controlId="formPassword" key="password">
                      <Form.Label>
                        <Icon size={"16px"} icon={key} />
                        &nbsp;{this.mandatory("senha")}
                      </Form.Label>
                      <Form.Control
                        type={isRevealPassword ? "text" : "password"}
                        autoComplete="new-password"
                        ref="password"
                        onChange={evt => this.formateField(evt)}
                        className={
                          this.state.errorField.includes("formPassword")
                            ? "errorBorder"
                            : ""
                        }
                      />
                      <span
                        onClick={this.togglePassword}
                        ref={this.iconRevealPassword}
                        onKeyDown={() => null}
                        role="button"
                        tabIndex={0}
                        style={{ outline: 0 }}
                      >
                        <div style={{ float: "right", marginTop: "-30px" }}>
                          {isRevealPassword ? (
                            <Icon
                              size={"20px"}
                              icon={eye}
                              style={{ marginRight: "6px" }}
                            />
                          ) : (
                            <Icon
                              size={"20px"}
                              icon={eyeSlash}
                              style={{ marginRight: "6px" }}
                            />
                          )}
                        </div>
                      </span>
                      <Form.Text className="text-muted">
                        {this.passWordRulesMsgs()}
                      </Form.Text>
                    </Form.Group>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Button
                      variant="primary"
                      onClick={this.verifyData}
                      style={{ float: "left" }}
                    >
                      Enviar
                    </Button>

                    <div
                      style={{
                        display: this.state.spinnerVerifying ? "block" : "none",
                        float: "left",
                        marginLeft: "6px",
                      }}
                    >
                      <Spinner animation="border" variant="secondary" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <div className="height-40" />
        <Footer />
      </>
    )
  }
}

export default PasswordRecovery
